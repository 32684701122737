import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src311988156/src/mad-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Tag, Space, IconChart, IconAdd } from '@madup-inc/mad-design';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tag"
    }}>{`Tag`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Tag} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`Icon, Color`}</p>
    <Playground __position={1} __code={'<Space>\n  <Tag>ready</Tag>\n  <Tag color=\"#f50\">#f50</Tag>\n  <Tag color=\"#2db7f5\">#2db7f5</Tag>\n  <Tag color=\"#87d068\">#87d068</Tag>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Tag,
      Space,
      IconChart,
      IconAdd,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Tag mdxType="Tag">ready</Tag>
    <Tag color='#f50' mdxType="Tag">#f50</Tag>
    <Tag color='#2db7f5' mdxType="Tag">#2db7f5</Tag>
    <Tag color='#87d068' mdxType="Tag">#87d068</Tag>
  </Space>
    </Playground>
    <h2 {...{
      "id": "preset-colors"
    }}>{`Preset colors`}</h2>
    <Playground __position={2} __code={'<Space>\n  <Tag color=\"red\">red</Tag>\n  <Tag color=\"volcano\">volcano</Tag>\n  <Tag color=\"orange\">orange</Tag>\n  <Tag color=\"gold\">gold</Tag>\n  <Tag color=\"lime\">lime</Tag>\n  <Tag color=\"green\">green</Tag>\n  <Tag color=\"cyan\">cyan</Tag>\n  <Tag color=\"blue\">blue</Tag>\n  <Tag color=\"geekblue\">geekblue</Tag>\n  <Tag color=\"purple\">purple</Tag>\n  <Tag color=\"magenta\">magenta</Tag>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Tag,
      Space,
      IconChart,
      IconAdd,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Tag color='red' mdxType="Tag">
      red
    </Tag>
    <Tag color='volcano' mdxType="Tag">
      volcano
    </Tag>
    <Tag color='orange' mdxType="Tag">
      orange
    </Tag>
    <Tag color='gold' mdxType="Tag">
      gold
    </Tag>
    <Tag color='lime' mdxType="Tag">
      lime
    </Tag>
    <Tag color='green' mdxType="Tag">
      green
    </Tag>
    <Tag color='cyan' mdxType="Tag">
      cyan
    </Tag>
    <Tag color='blue' mdxType="Tag">
      blue
    </Tag>
    <Tag color='geekblue' mdxType="Tag">
      geekblue
    </Tag>
    <Tag color='purple' mdxType="Tag">
      purple
    </Tag>
    <Tag color='magenta' mdxType="Tag">
      magenta
    </Tag>
  </Space>
    </Playground>
    <h2 {...{
      "id": "with-icon"
    }}>{`With icon`}</h2>
    <Playground __position={3} __code={'<Space>\n  <Tag icon={<IconChart />} color=\"#108ee9\">\n    #108ee9\n  </Tag>\n  <Tag color=\"purple\" icon={<IconAdd />}>\n    purple\n  </Tag>\n  <Tag color=\"red\" icon={<div>&</div>}>\n    blueming\n  </Tag>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Tag,
      Space,
      IconChart,
      IconAdd,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Tag icon={<IconChart mdxType="IconChart" />} color='#108ee9' mdxType="Tag">
      #108ee9
    </Tag>
    <Tag color='purple' icon={<IconAdd mdxType="IconAdd" />} mdxType="Tag">
      purple
    </Tag>
    <Tag color='red' icon={<div>&</div>} mdxType="Tag">
      blueming
    </Tag>
  </Space>
    </Playground>
    <h2 {...{
      "id": "different-width"
    }}>{`Different width`}</h2>
    <Playground __position={4} __code={'<Space>\n  <Tag color=\"purple\" width={300}>\n    ready\n  </Tag>\n  <Tag color=\"cyan\" width={200}>\n    stop\n  </Tag>\n  <Tag color=\"blue\" width={100}>\n    start\n  </Tag>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Tag,
      Space,
      IconChart,
      IconAdd,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Tag color="purple" width={300} mdxType="Tag">ready</Tag>
    <Tag color='cyan' width={200} mdxType="Tag">
      stop
    </Tag>
    <Tag color='blue' width={100} mdxType="Tag">
      start
    </Tag>
  </Space>
    </Playground>
    <h2 {...{
      "id": "with-closable"
    }}>{`With closable`}</h2>
    <Playground __position={5} __code={'<Space>\n  <Tag>cannot close</Tag>\n  <Tag closable onClose={() => alert(\'closable\')}>\n    closable\n  </Tag>\n</Space>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Tag,
      Space,
      IconChart,
      IconAdd,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space mdxType="Space">
    <Tag mdxType="Tag">cannot close</Tag>
    <Tag closable onClose={() => alert('closable')} mdxType="Tag">
      closable
    </Tag>
  </Space>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      